<template>
  <CModal :show="show" :closeOnBackdrop="false" :centered="true" size="xl">
    <template #header>
      <h5 class="modal-title">{{ title }}</h5>
    </template>

    <CAlert v-if="error !== null" color="danger" class="mb-3">
      {{ error }}
    </CAlert>

    <form action="" @submit.prevent="onSubmit" ref="form" v-if="haveIncident()">
      <div class="ui-type-heading mb-3">
        S{{ incidenId() }} - {{ incidentFamilyName() }}
      </div>

      <div class="ui-type-subheading text-uppercase">
        {{ $t("role.orders.manage.incident.modal.modify.article") }}
      </div>

      <CRow class="mb-3">
        <CCol md="3"
          ><strong>{{ incidentDetailVariantName() }}</strong
          ><br />{{ incidentDetailPriceOrig() }}</CCol
        >
        <CCol md="3"
          ><strong
            >{{ $t("role.orders.manage.incident.modal.modify.order") }} #{{
              incidentDetailOrderId()
            }}</strong
          ><br />{{ incidentDetailOrderFamilyName() }}</CCol
        >
        <CCol md="3"><div v-html="incidentDetailStatus()"></div></CCol>
      </CRow>

      <div class="ui-type-subheading text-uppercase">
        {{ $t("role.orders.manage.incident.modal.modify.customerManagement") }}
      </div>

      <CRow class="mb-3">
        <CCol md="3">
          <CSelect
            :value.sync="form.action"
            :options="actionOptions"
            :disabled="!canBeEdit()"
            custom
          >
            <template #label>
              <strong>{{
                $t("role.orders.manage.incident.modal.modify.action")
              }}</strong>
              <em class="text-danger">*</em>
            </template>
          </CSelect>
        </CCol>
        <CCol md="3" v-if="showReplacedWith">
          <CInput v-model="form.replacedWith" :readonly="!canBeEdit()">
            <template #label>
              <strong>{{
                $t("role.orders.manage.incident.modal.modify.replacedWith")
              }}</strong>
              <em class="text-danger">*</em>
            </template>
          </CInput>
        </CCol>
        <CCol md="3" v-if="showNewWeight">
          <CInput
            v-model="form.newWeight"
            type="number"
            min="0"
            step="0.1"
            :append="unit"
            :readonly="!canBeEdit()"
          >
            <template #label>
              <strong>{{
                $t("role.orders.manage.incident.modal.modify.weightChanged")
              }}</strong>
              <em class="text-danger">*</em>
            </template>
          </CInput>
        </CCol>
        <CCol md="3" v-if="showPriceNew">
          <CInput
            v-model="form.priceNew"
            :append="currencySymbol"
            :disabled="disablePriceNew"
            :isValid="fieldIsValid(`price`)"
            :invalidFeedback="fieldError(`price`)"
            type="number"
            step="0.01"
            :readonly="!canBeEdit()"
          >
            <template #label>
              <strong>{{
                $t("role.orders.manage.incident.modal.modify.newClientPrice")
              }}</strong>
              <em class="text-danger">*</em>
            </template>
          </CInput>
        </CCol>
      </CRow>

      <div v-if="showPriceDetails">
        <div class="ui-type-subheading text-uppercase">
          {{ $t("role.orders.manage.incident.modal.modify.cashManagement") }}
        </div>

        <div class="mb-3">
          <CRow class="align-items-center">
            <CCol md="3">
              <CInput
                v-model="form.priceProducer"
                :append="currencySymbol"
                :disabled="disablePriceProducer"
                :isValid="fieldIsValid(`price_producer`)"
                :invalidFeedback="fieldError(`price_producer`)"
                type="number"
                step="0.01"
                :readonly="!canBeEdit()"
              >
                <template #label>
                  <strong>{{
                    $t("role.orders.manage.incident.modal.modify.producer")
                  }}</strong>
                  <em class="text-danger">*</em>
                </template>
              </CInput>
            </CCol>
            <CCol md="3">
              <CInput
                v-model="form.priceMarket"
                :append="currencySymbol"
                :disabled="disablePriceMarket"
                :isValid="fieldIsValid(`price_market`)"
                :invalidFeedback="fieldError(`price_market`)"
                type="number"
                step="0.01"
                :readonly="!canBeEdit()"
              >
                <template #label>
                  <strong>{{
                    $t("role.orders.manage.incident.modal.modify.market")
                  }}</strong>
                  <em class="text-danger">*</em>
                </template>
              </CInput>
            </CCol>
            <CCol md="3">
              <CInput
                v-model="form.priceIsland"
                :append="currencySymbol"
                :disabled="disablePriceIsland"
                :isValid="fieldIsValid(`price_island`)"
                :invalidFeedback="fieldError(`price_island`)"
                type="number"
                step="0.01"
                :readonly="!canBeEdit()"
              >
                <template #label>
                  <strong>{{
                    $t("role.orders.manage.incident.modal.modify.delivery")
                  }}</strong>
                  <em class="text-danger">*</em>
                </template>
              </CInput>
            </CCol>
            <CCol md="3" v-if="canBeEdit()">
              <CDropdown
                :togglerText="
                  $t('role.orders.manage.incident.modal.modify.recalculate')
                "
                color="primary"
                :disabled="disableActionRicButton"
              >
                <CDropdownItem
                  v-for="action in actionPriceOptions"
                  :key="action.value"
                  @click="onAction(action.value)"
                  >{{ action.label }}</CDropdownItem
                >
              </CDropdown>
            </CCol>
          </CRow>

          <CRow v-if="pricesInconsistence">
            <CCol
              ><div class="text-secondary-darker">
                <font-awesome-icon icon="exclamation-triangle" /><span
                  class="ml-1"
                  >{{
                    $t(
                      "role.orders.manage.incident.modal.modify.sumNonRersolved"
                    )
                  }}
                  {{ getPriceToMatch(true) }}</span
                >
              </div></CCol
            >
          </CRow>
        </div>
      </div>

      <div class="ui-type-subheading text-uppercase">
        {{ $t("role.orders.manage.incident.modal.modify.details") }}
      </div>

      <CRow class="mb-3">
        <CCol md="6">
          <CInput v-model="form.note" :readonly="!canBeEdit()">
            <template #label>
              <strong>{{
                $t("role.orders.manage.incident.modal.modify.note")
              }}</strong>
            </template>
          </CInput>
        </CCol>
      </CRow>
    </form>

    <template #footer>
      <CButton color="primary" variant="outline" @click.prevent="close">{{
        $t("role.orders.manage.incident.modal.btnCancel")
      }}</CButton>
      <vue-ladda
        v-if="canBeEdit()"
        :loading="loading"
        data-style="zoom-in"
        button-class="btn btn-primary px-4"
        @click.prevent="onSubmit"
        >{{ $t("role.orders.manage.incident.modal.btnConfirm") }}</vue-ladda
      >
    </template>
  </CModal>
</template>

<script>
import get from "lodash/get";
import { mapGetters } from "vuex";
import {
  INCIDENT_ACTIONS,
  INCIDENT_ACTIONS_PRICE,
} from "../../../../config/global";
import { formatPrice, getOptionLabel } from "../../../../helpers/common";
import {
  presentIncidentDetailStatus,
  round,
  calculateDiscounted,
  calculatePercentage,
} from "../../../../helpers/incident";
import EventBus from "../../../../helpers/EventBus";
import { getPriceUnitOptions } from "../../../../helpers/options";

export default {
  name: "OrdersIncidentEditModal",

  data() {
    return {
      INCIDENT_ACTIONS_PRICE,
      form: {
        action: INCIDENT_ACTIONS.DISCOUNT,
        newWeight: "",
        replacedWith: "",
        priceNew: "",
        priceProducer: "",
        priceMarket: "",
        priceIsland: "",
        note: "",
      },
      loading: false,
      error: null,
    };
  },

  computed: {
    title() {
      return this.$t("role.orders.manage.incident.modal.modify.title");
    },

    show() {
      return this.$store.state.incidents.edit.show;
    },

    record() {
      return this.$store.state.incidents.edit.incidentDetail;
    },

    lang() {
      return this.$i18n.locale;
    },

    unit() {
      return getOptionLabel(
        get(this.record, "ppm.lot.productproducer.pw_unit"),
        getPriceUnitOptions(this.$i18n)
      );
    },

    actionOptions() {
      const actions = [
        {
          value: INCIDENT_ACTIONS.CANCELLED,
          label: this.$t("role.orders.manage.incident.modal.modify.canceled"),
          enabled: true,
        },
        {
          value: INCIDENT_ACTIONS.CANCELLED_FOR,
          label: this.$t(
            "role.orders.manage.incident.modal.modify.canceledAtCostOf"
          ),
          enabled: true,
        },

        {
          value: INCIDENT_ACTIONS.REPLACEMENT,
          label: this.$t("role.orders.manage.incident.modal.modify.replaced"),
          enabled: true,
        },
        {
          value: INCIDENT_ACTIONS.DISCOUNT,
          label: this.$t("role.orders.manage.incident.modal.modify.discounted"),
          enabled: true,
        },
        {
          value: INCIDENT_ACTIONS.CHANGE_WEIGHT,
          label: this.$t(
            "role.orders.manage.incident.modal.modify.weightChanged"
          ),
          enabled: this.isWeightProduct(),
        },
      ];

      return actions.filter((action) => action.enabled);
    },

    actionPriceOptions() {
      const actionsPrice = [
        {
          value: INCIDENT_ACTIONS_PRICE.PROPORTIONALLY,
          label: this.$t("incident.modal.price_action_options.divide"),
          enabled: this.form.action !== INCIDENT_ACTIONS.CANCELLED_FOR,
        },
        {
          value: INCIDENT_ACTIONS_PRICE.CHARGE_PRODUCER,
          label: this.$t("incident.modal.price_action_options.producer"),
          enabled: true,
        },

        {
          value: INCIDENT_ACTIONS_PRICE.CHARGE_MARKET,
          label: this.$t("incident.modal.price_action_options.market"),
          enabled: true,
        },
        {
          value: INCIDENT_ACTIONS_PRICE.CHARGE_ISLAND,
          label: this.$t("incident.modal.price_action_options.island"),
          enabled: true,
        },
      ];

      return actionsPrice.filter((action) => action.enabled);
    },

    showPriceNew() {
      return [
        INCIDENT_ACTIONS.DISCOUNT,
        INCIDENT_ACTIONS.REPLACEMENT,
        INCIDENT_ACTIONS.CHANGE_WEIGHT,
      ].includes(this.form.action);
    },

    disablePriceNew() {
      return [INCIDENT_ACTIONS.CHANGE_WEIGHT].includes(this.form.action);
    },

    disablePriceProducer() {
      return [INCIDENT_ACTIONS.CANCELLED].includes(this.form.action);
    },

    disablePriceMarket() {
      return [INCIDENT_ACTIONS.CANCELLED].includes(this.form.action);
    },

    disablePriceIsland() {
      return [INCIDENT_ACTIONS.CANCELLED].includes(this.form.action);
    },

    showReplacedWith() {
      return [INCIDENT_ACTIONS.REPLACEMENT].includes(this.form.action);
    },

    showNewWeight() {
      return [INCIDENT_ACTIONS.CHANGE_WEIGHT].includes(this.form.action);
    },

    showPriceDetails() {
      return this.form.action !== INCIDENT_ACTIONS.CHANGE_WEIGHT;
    },

    pricesInconsistence() {
      const price = this.getPriceToMatch();
      const { priceProducer, priceMarket, priceIsland } = this.form;
      const partialsSum =
        parseFloat(priceProducer) +
        parseFloat(priceMarket) +
        parseFloat(priceIsland);

      return round(price) !== round(partialsSum);
    },

    disableActionRicButton() {
      return [INCIDENT_ACTIONS.CANCELLED].includes(this.form.action);
    },

    ...mapGetters("connections", ["currencySymbol"]),
  },

  watch: {
    show(value) {
      // reset on open/close modal
      if (value === true) {
        this.setup();
      } else {
        // this.reset();
      }
    },

    "form.action"(value) {
      const {
        orig_price,
        orig_credit_producer,
        orig_credit_market,
        orig_credit_island,
        credit_producer,
        credit_market,
        credit_island,
      } = this.getPrices();

      if (
        [INCIDENT_ACTIONS.REPLACEMENT, INCIDENT_ACTIONS.DISCOUNT].includes(
          value
        )
      ) {
        this.form.priceNew = orig_price;
        this.form.priceProducer = orig_credit_producer;
        this.form.priceMarket = orig_credit_market;
        this.form.priceIsland = orig_credit_island;
      }

      if ([INCIDENT_ACTIONS.CANCELLED].includes(value)) {
        this.form.priceNew = 0;
        this.form.priceProducer = 0;
        this.form.priceMarket = 0;
        this.form.priceIsland = 0;
      }

      if ([INCIDENT_ACTIONS.CANCELLED_FOR].includes(value)) {
        this.form.priceNew = 0;
        this.form.priceProducer = credit_producer;
        this.form.priceMarket = credit_market;
        this.form.priceIsland = credit_island;
      }

      if ([INCIDENT_ACTIONS.CHANGE_WEIGHT].includes(value)) {
        const pricePerUnit = this.pricePerUnit();
        this.form.priceNew = round(pricePerUnit * this.form.newWeight);
        this.form.priceProducer = orig_credit_producer;
        this.form.priceMarket = orig_credit_market;
        this.form.priceIsland = orig_credit_island;
      }
    },

    "form.newWeight"(value) {
      if (this.form.action === INCIDENT_ACTIONS.CHANGE_WEIGHT) {
        const pricePerUnit = this.pricePerUnit();
        this.form.priceNew = round(pricePerUnit * value);
      }
    },
  },

  methods: {
    reset() {
      this.loading = false;
      this.error = null;
      this.form = {
        action: INCIDENT_ACTIONS.DISCOUNT,
        newWeight: "",
        replacedWith: "",
        priceNew: "",
        priceProducer: "",
        priceMarket: "",
        priceIsland: "",
        note: "",
      };
    },

    setup() {
      this.form = {
        action: get(
          this.record,
          "incident.detail.extra.action",
          INCIDENT_ACTIONS.DISCOUNT
        ),
        newWeight: get(this.record, "qta", 0),
        replacedWith: get(
          this.record,
          "incident.detail.extra.replacedWith",
          ""
        ),
        note: get(this.record, "incident.detail.extra.note", ""),
        priceNew: get(this.record, "price", 0),
        priceProducer: get(this.record, "credit_producer", 0),
        priceMarket: get(this.record, "credit_market", 0),
        priceIsland: get(this.record, "credit_island", 0),
      };
    },

    haveIncident() {
      return get(this.record, "incident", null) !== null;
    },

    close() {
      this.$store.commit("incidents/editHide");
    },

    incidenId() {
      return get(this.record, "incident.id");
    },

    incidentFamilyName() {
      return get(this.record, "incident.by.family.name");
    },

    incidentDetailOrderId() {
      return get(this.record, "order.id");
    },

    incidentDetailOrderFamilyName() {
      return get(this.record, "order.family.name");
    },

    incidentDetailPriceOrig() {
      return formatPrice(
        get(this.record, "incident.detail.orig_price", 0),
        this.currencySymbol
      ).format();
    },

    incidentDetailVariantName() {
      const productName = get(
        this.record,
        `ppm.lot.productproducer.translations.${this.lang}.name`
      );

      const variantName = get(
        this.record,
        `ppm.lot.translations.${this.lang}.name`
      );

      return `${productName} - ${variantName}`;
    },

    incidentDetailStatus() {
      return presentIncidentDetailStatus(this.record, this.$i18n);
    },

    isWeightProduct() {
      return get(this.record, "ppm.lot.productproducer.pw", false) === true;
    },

    getPrices() {
      return {
        orig_price: get(this.record, "incident.detail.orig_price", 0),
        orig_credit_producer: get(
          this.record,
          "incident.detail.orig_credit_producer",
          0
        ),
        orig_credit_market: get(
          this.record,
          "incident.detail.orig_credit_market",
          0
        ),
        orig_credit_island: get(
          this.record,
          "incident.detail.orig_credit_island",
          0
        ),
        credit_producer: get(this.record, "credit_producer"),
        credit_market: get(this.record, "credit_market"),
        credit_island: get(this.record, "credit_island"),
      };
    },

    pricePerUnit() {
      const { price, qta } = this.record;
      return round(price / qta);
    },

    getPriceToMatch(format = false) {
      return format
        ? formatPrice(this.form.priceNew, this.currencySymbol).format()
        : this.form.priceNew;
    },

    onAction(actionRic) {
      const { action } = this.form;
      const {
        orig_price,
        orig_credit_producer,
        orig_credit_market,
        orig_credit_island,
      } = this.getPrices();
      const price = this.getPriceToMatch();
      const discount = round(orig_price - price);
      const perc = calculatePercentage(orig_price, price);

      if (actionRic === INCIDENT_ACTIONS_PRICE.PROPORTIONALLY) {
        this.form.priceProducer = calculateDiscounted(
          orig_credit_producer,
          perc
        );
        this.form.priceIsland = calculateDiscounted(orig_credit_island, perc);
        this.form.priceMarket = calculateDiscounted(orig_credit_market, perc);
      }

      if (actionRic === INCIDENT_ACTIONS_PRICE.CHARGE_PRODUCER) {
        if (action === INCIDENT_ACTIONS.CANCELLED_FOR) {
          this.form.priceProducer = round(
            -(orig_credit_island + orig_credit_market)
          );
        } else {
          this.form.priceProducer = round(orig_credit_producer - discount);
        }
        this.form.priceMarket = orig_credit_market;
        this.form.priceIsland = orig_credit_island;
      }

      if (actionRic === INCIDENT_ACTIONS_PRICE.CHARGE_MARKET) {
        if (action === INCIDENT_ACTIONS.CANCELLED_FOR) {
          this.form.priceMarket = round(
            -(orig_credit_producer + orig_credit_island)
          );
        } else {
          this.form.priceMarket = round(orig_credit_market - discount);
        }
        this.form.priceProducer = orig_credit_producer;
        this.form.priceIsland = orig_credit_island;
      }

      if (actionRic === INCIDENT_ACTIONS_PRICE.CHARGE_ISLAND) {
        if (action === INCIDENT_ACTIONS.CANCELLED_FOR) {
          this.form.priceIsland = round(
            -(orig_credit_producer + orig_credit_market)
          );
        } else {
          this.form.priceIsland = round(orig_credit_island - discount);
        }
        this.form.priceProducer = orig_credit_producer;
        this.form.priceMarket = orig_credit_market;
      }
    },

    fieldIsValid(key) {
      const s =
        get(this.error, `errors.fields.${key}`, undefined) !== undefined
          ? false
          : undefined;
      return s;
    },

    fieldError(key) {
      const val = get(this.error, `errors.fields.${key}`, undefined);
      if (val !== undefined) {
        return Array.isArray(val) ? val.join(" ") : val;
      }
      return "";
    },

    onSubmit() {
      const {
        action,
        priceNew,
        priceProducer,
        priceIsland,
        priceMarket,
        note,
        newWeight,
        replacedWith,
      } = this.form;

      const extra = {
        action: action,
        note: note,
        newWeight: newWeight,
        replacedWith: replacedWith,
      };

      let formData = {
        detail_id: this.record.id,
        extra,
      };

      if (action !== INCIDENT_ACTIONS.CHANGE_WEIGHT) {
        formData = {
          ...formData,
          price: round(priceNew),
          price_producer: round(priceProducer),
          price_island: round(priceIsland),
          price_market: round(priceMarket),
        };
      }

      this.error = null;

      this.$store
        .dispatch("incidents/editIncident", formData)
        .then(() => {
          // this.$store.commit("incidents/resetState");
          this.$store.commit("incidents/editHide");
          EventBus.$emit("orders:refresh");
        })
        .catch((error) => {
          console.error(error);
          this.error = error.response.data.errors.general[0];
        })
        .finally(() => {
          this.loading = false;
        });
    },

    canBeEdit() {
      return get(this.record, "incident.can_edit", true);
    },
  },
};
</script>
